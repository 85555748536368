import React from 'react';
import Confetti from './confetti';
import { ReactFitty } from 'react-fitty';
import InfoIcon from '../fonts/icons/info-circle.svg';
import Graph from '../fonts/icons/graph.svg';

const storageKey = "numble";
const bigs = [125,80,35,150,75,45,100,15,50,165];
const smalls = [7,9,2,8,3,7,4,6,5,10];
const operators = ['-','×','÷','×','-','×','÷','×','-','×'];

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.gameSize = 5;
    this.gameDate = new Date();
    this.numbers = [75, 80, 2, 6, 5];
    this.target = 207;
    this.operators = ["+", "-", "×", "÷"];
    this.stats = {
      lastGame: null,
      played: 0,
      streak: 0,
      perfect: 0
    };
    this.state = {
      // Array to track the numbers that have already been used
      selNumbers: [],
      // Full representation of the workspace, including calculated numbers
      workspace: Array.from(new Array(this.gameSize - 1), () => new Array(3).fill(null)),
      // Pointer for the active cell, coordinate in the workspace matrix
      pointer: [0,0],
      // The player's final output
      finalSum: null,
      // The time left until the next game
      timeLeft: "",
      // Booleans for displaying the game end modal, info modal, and copy text
      showModal: false,
      showInfo: false,
      showCopy: false,
      supressModal: false
    };
  }

  componentDidMount() {
    this.generateGame();
    this.updateTimer();
    this.timer = setInterval(
      () => this.updateTimer(),
      1000
    );
    this.loadHistory();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  generateGame() {
    let dateParts = this.gameDate.toLocaleDateString().split("/");
    let seed = dateParts[0] * dateParts[1] * dateParts[2] * 421 * 1991;
    let indecies = seed.toString().split("").reverse().join("").slice(1,6).split('');
    let displayNumbers = [];
    let numbers = [];
    let numberSort = [];
    numberSort.push({num: smalls[indecies[0]], ind:indecies[0]});
    numberSort.push({num: bigs[indecies[1]], ind:indecies[1]});
    numberSort.push({num: smalls[indecies[2]], ind:indecies[2]});
    numberSort.push({num: bigs[indecies[3]], ind:indecies[3]});
    numberSort.push({num: smalls[indecies[4]], ind:indecies[4]});

    displayNumbers.push(smalls[indecies[0]]);
    displayNumbers.push(bigs[indecies[1]]);
    displayNumbers.push(smalls[indecies[2]]);
    displayNumbers.push(bigs[indecies[3]]);
    displayNumbers.push(smalls[indecies[4]]);

    numberSort.sort((a,b) => a.ind - b.ind);
    for(let x=0; x< numberSort.length; x++){
      numbers.push(numberSort[x]["num"]);
    }

    let target = 0;
    for(var x=0; x < numbers.length; x++){
      if(x===0){
        target = numbers[x];
      } else {
        switch (operators[indecies[x]]) {
          case '+':
            target = target + numbers[x];
            break;
          case '-':
            // if subtraction results in positive number, subtract
            if((target - numbers[x]) > 0){
              target = target - numbers[x];
            // if dividing doesnt result in a fraction, divide
            } else if(
              ((target / numbers[x])%1 === 0) &&
              (numbers[x] > 1) &&
              ((target / numbers[x]) > 1)
            ){
              target = target / numbers[x];
            // if multiplying results in no more than a three digit number, multiply
            } else if(
              ((target * numbers[x]).toString().length <= 3) &&
              (numbers[x] > 1)
            ){
              target = target * numbers[x];
            // if all that fails... add
            } else {
              target = target + numbers[x];
            }
            break;
          case '×':
            if(
              ((target * numbers[x]).toString().length <= 3) &&
              (numbers[x] > 1)
            ) {
              target = target * numbers[x];
            } else {
              target = target + numbers[x];
            }
            break;
          case '÷':
            if(
              ((target / numbers[x])%1 === 0) &&
              (numbers[x] > 1) &&
              ((target / numbers[x]) > 1)
            ){
              target = target / numbers[x];
            } else if((x%2 === 0) && ((target - numbers[x]) > 0)) {
              target = target - numbers[x];
            } else {
              target = target + numbers[x];
            }
            break;
          default:
            break;
        }
      }
    }

    this.numbers = displayNumbers;
    this.target = target;
  }

  updateTimer() {
    let date = new Date();
    let hours = ("0" + (23 - date.getHours())).slice(-2);
    let minutes = ("0" + (59 - date.getMinutes())).slice(-2);
    let seconds = ("0" + (59 - date.getSeconds())).slice(-2);
    this.setState({timeLeft: (hours + ":" + minutes + ":" + seconds)});

    if(date.toLocaleDateString() !== this.gameDate.toLocaleDateString()){
      clearInterval(this.timer);
      this.setState({timeLeft: "refresh"});
    }
  }

  // History Object:
  // {
  //   lastUpdated: "10/17/2022",
  //   state: {
  //     workspace: [][],
  //     selNumbers: [],
  //     pointer: [][],
  //     finalSum: null,
  //     showModal: true
  //   }
  //   stats: {
  //     lastGame: "10/17/2022",
  //     totalPlayed: 1,
  //     currentStreak: 1,
  //     perfect: 0
  //   }
  // }
  loadHistory() {
    let currentDate = this.gameDate.toLocaleDateString();
    let history = JSON.parse(localStorage.getItem(storageKey));
    // If no history exists
    if(!history){
      // TODO: Show Instruction Modal
      this.showInfo();
      // Create new history with the current date and state
      history = {
        lastUpdated: currentDate,
        state: this.state
      }
      localStorage.setItem(storageKey, JSON.stringify(history));
      return false;
    // If history already exists for today
    } else if(history.lastUpdated === currentDate) {
      // Load the current game state
      this.setState(history.state);
      if(history.stats){this.stats = history.stats;}
    // If history exists but not for today
    } else {
      // Update to the current date and state
      history.lastUpdated = this.gameDate.toLocaleDateString();
      history.state = this.state;
      this.stats = history.stats ? history.stats : this.stats;
      localStorage.setItem(storageKey, JSON.stringify(history));
    }
  }

  updateStats() {
    let history = JSON.parse(localStorage.getItem(storageKey));
    let currentDate = this.gameDate.toLocaleDateString();
    // If they already played today, only update on a perfect game
    if(this.stats.lastGame === currentDate) {
      this.stats.perfect = (this.checkEqual() ? (this.stats.perfect + 1) : this.stats.perfect)
    // If this is the first game today, update all stats
    } else {
      this.stats = {
        lastGame: currentDate,
        played: (this.stats.played + 1),
        streak: this.getCurrentStreak(this.stats),
        perfect: (this.checkEqual() ? (this.stats.perfect + 1) : this.stats.perfect)
      }
    }
    history.stats = this.stats;
    history.state = this.state;
    localStorage.setItem(storageKey, JSON.stringify(history));
  }

  getCurrentStreak(stats) {
    if(!stats.lastGame){return 1;}
    let prevGame = new Date(stats.lastGame);
    let currentGame = new Date(this.gameDate.toLocaleDateString());
    const diffTime = Math.abs(currentGame - prevGame);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if(diffDays === 1){
      return (stats.streak + 1);
    } else {
      return 1;
    }
  }

  updateHistory() {
    let history = JSON.parse(localStorage.getItem(storageKey));
    history.state = this.state;
    localStorage.setItem(storageKey, JSON.stringify(history));
  }

  addOperator(operator) {
    let p = this.state.pointer;
    // If we're not in the operator position or we've passed the final row
    if((p[1] !== 1) || ((p[0] + 1) > this.state.workspace.length)){
      // Shake animation or something
      return;
    } else {
      let workspace = this.state.workspace
      workspace[p[0]][p[1]] = operator;
      this.setState({workspace: workspace});
      this.advancePointer();
    }
  }

  addNumber(number, index) {
    let p = this.state.pointer;
    let selNumbers = this.state.selNumbers
    if(this.state.showModal || selNumbers.includes(index) || p[1] === 1){return;}
    let workspace = this.state.workspace
    workspace[p[0]][p[1]] = number;
    selNumbers.push(index);
    // If last digit in row, calculate next number
    if(p[1] === 2){
      let currentSum = workspace[p[0]][0];
      let currentOp = workspace[p[0]][1];
      let newSum;
      switch (currentOp) {
        case '+':
          newSum = currentSum + number;
          break;
        case '-':
          newSum = currentSum - number;
          break;
        case '×':
          newSum = currentSum * number;
          break;
        case '÷':
          newSum = currentSum / number;
          break;
        default:
          console.log("Invalid Operator");
      }
      let roundSum = Math.round(newSum * 1000) / 1000;
      if((p[0] + 1) === workspace.length){
        this.setState({
          finalSum: roundSum
        }, () => {this.updateStats()});
        if(!this.state.supressModal || (roundSum === this.target)){this.showModal(true);}
      } else {
        workspace[p[0]+1][0] = roundSum
      }
    }
    this.setState({
      workspace: workspace,
      selNumbers: selNumbers
    }, () => {this.updateHistory()});
    this.advancePointer();
  }

  advancePointer() {
    let p = this.state.pointer;
    // If this is the first or second tile in the row
    if(p[1] < 2){
      // Move to the right
      p[1]++;
    // If this is the last tile in the row
    } else if(p[1] === 2) {
      // Move down a row and to the second position (leaving room for the sum)
      p[0]++;
      p[1] = 1;
    }
    this.setState({pointer: p}, () => {this.updateHistory()});
  }

  undo() {
    // No undo if the modal is animating or they reached the target score
    if(this.state.showModal || this.checkEqual()){return;}
    let p = this.state.pointer;
    // If we're in the first position theres nothing to undo
    if(p[1] === 0){return;}
    // If the pointer is on the last tile in the row
    if(p[1] === 2){
      // Move to the pointer left and clear out that spot
      p[1]--;
      this.clearTile(p[0],p[1]);
    // If the pointer is on the operator
    } else if(p[1] === 1) {
      // If we're not past the final row
      if((p[0] + 1) <= this.state.workspace.length){
        // Remove the value in the first tile (sum)
        this.clearTile(p[0],0);
      } else {
        // If we are past the final row, remove the final sum
        this.setState({finalSum: null}, () => {this.updateHistory()});
      }
      // If this is not the first row
      if(p[0] > 0){
        // Move back a row to the last tile and remove that value
        p[0]--;
        p[1] = 2;
        this.clearTile(p[0],p[1]);
      // Otherwise, set the position to the first tile
      } else {
        p[1] = 0;
      }
    }
    this.setState({
      pointer: p
    }, () => {this.updateHistory()});

  }

  getAllIndexes(arr, val) {
    var indexes = [], i;
    for(i = 0; i < arr.length; i++)
        if (arr[i] === val)
            indexes.push(i);
    return indexes;
  }

  clearTile(x,y) {
    let workspace = this.state.workspace;
    let selNumbers = this.state.selNumbers;
    let numToRemove = workspace[x][y];
    if(y > 0 || x === 0){
      let indecies = this.getAllIndexes(this.numbers, numToRemove);
      for(let i=0; i<indecies.length; i++){
        if(selNumbers.indexOf(indecies[i]) >= 0){
          selNumbers.splice(selNumbers.indexOf(indecies[i]), 1);
          break;
        }
      }
    }
    workspace[x][y] = null;
    this.setState({
      workspace: workspace,
      selNumbers: selNumbers
    }, () => {this.updateHistory()});
  }

  checkActive(rowIndex, tileIndex) {
    let point = this.state.pointer;
    return (point[0] === rowIndex) && (point[1] === tileIndex)
  }

  checkUsed(index) {
    return this.state.selNumbers.includes(index);
  }

  checkEqual() {
    return (this.state.finalSum === this.target);
  }

  workspace() {
    return this.state.workspace.map((row, rowIndex) => {
      return <div key={rowIndex} className="workspace--row">
          <div className={`tile number ${this.checkActive(rowIndex, 0) ? "active" : ""}`}>
            <ReactFitty maxSize="24">{row[0]}</ReactFitty>
          </div>
          <div className={`tile operator ${this.checkActive(rowIndex, 1) ? "active" : ""}`}>
            <ReactFitty maxSize="24">{row[1]}</ReactFitty>
          </div>
          <div className={`tile number ${this.checkActive(rowIndex, 2) ? "active" : ""}`}>
            <ReactFitty maxSize="24">{row[2]}</ReactFitty>
          </div>
      </div>
    });
  }

  numbersControl() {
    return this.numbers.map((number, index) => {
      return <button
        key={index}
        className={`number ${this.checkUsed(index) ? "used" : ""}`}
        onClick={() => this.addNumber(number, index)}
      >{number}</button>
    });
  }

  operatorControl() {
    return this.operators.map((operator, index) => {
      return <button
        key={index}
        className="operator"
        onClick={() => this.addOperator(operator)}
      >{operator}</button>
    });
  }

  confettiContainer() {
    if (this.checkEqual()) {
      return <Confetti/>
    }
  }

  showFinalSum() {
    return (!this.checkEqual() && (this.state.finalSum !== null));
  }

  showModal(delay=false) {
    var me = this;
    if(delay){
      setTimeout(function(){
        me.setState({showModal: true}, () => {me.updateHistory()});
      }, 750);
    } else {
      this.setState({showModal: true}, () => {this.updateHistory()});
    }
  }

  closeModal() {
    if(this.state.finalSum !== null){
      this.setState({
        showModal: false,
        supressModal: true
      }, () => {this.updateHistory()});
    } else {
      this.setState({showModal: false}, () => {this.updateHistory()});
    }
  }

  showInfo() {
    this.setState({showInfo: true}, () => {this.updateHistory()});
  }

  closeInfo() {
    this.setState({showInfo: false}, () => {this.updateHistory()});
  }

  share() {
    navigator.clipboard.writeText("Check out Daisy Chain! https://playdaisychain.com");
    this.setState({showCopy: true});
  }

  modalHeading() {
    if(!this.state.finalSum){
      return <div className="heading">Your Stats</div>
    } else if(this.checkEqual()) {
      return <div className="heading">Well Done!</div>
    } else if(Math.abs(this.target - this.state.finalSum) < 50) {
      return <div className="heading">So Close!</div>
    } else if(Math.abs(this.target - this.state.finalSum) < 200){
      return <div className="heading">Almost!</div>
    } else {
      return <div className="heading">Keep Trying!</div>
    }
  }

  modalActions() {
    let buttonText = this.state.finalSum ? "Keep Trying" : "Back"
    return <div className="actions">
      {!this.checkEqual() &&
      <button className="try-again" onClick={() => this.closeModal()}>{buttonText}</button>
      }
      <div className="share-wrap">
        <button className="share" onClick={() => this.share()}>Share</button>
        <div
          className={`copied ${this.state.showCopy ? "show" : ""}`}
          onAnimationEnd={() => this.setState({showCopy: false})}>
          Copied!
        </div>
      </div>
    </div>
  }

  getTimer() {
    if(this.state.timeLeft === "refresh"){
      return <button className="refresh" onClick={() => (window.location.reload())}>Play Now</button>
    } else {
      return <div className="timer">{this.state.timeLeft}</div>
    }
  }

  render() {
    return (
      <div className="app-container">
        <button className="stat-toggle" onClick={() => this.showModal()}>
          <img src={Graph} alt="Show Stats"/>
        </button>
        <button className="info-toggle" onClick={() => this.showInfo()}>
          <img src={InfoIcon} alt="Show Info"/>
        </button>

        <div className={`info-container ${this.state.showInfo ? "show" : ""}`}>
          <div className="modal">
            <div className="close" onClick={() => this.closeInfo()}>×</div>
            <div className="header">How To Play</div>
            <div className="sub-header">Using the numbers and operators at the bottom of the screen create a series of equations that result in the target score.</div>
            <ul>
              <li>Every number must be used once.</li>
              <li>The result of each row becomes the start of the following row.</li>
              <li>Fractions are discouraged, but allowed (rounded to three places).</li>
              <li>It's always possible to reach the target score.</li>
            </ul>
            <div className="actions">
              <button className="play" onClick={() => this.closeInfo()}>Let's Play!</button>
            </div>
            <div className="notes">A new puzzle will be released daily</div>
          </div>
        </div>

        <header>Daisy Chain</header>
        <div className="workspace">
          {this.workspace()}
          <div className="target-container">
            <div className={`final-sum ${this.showFinalSum() ? "" : "empty"}`}>
              <ReactFitty maxSize="50">{this.showFinalSum() ? this.state.finalSum : null}</ReactFitty>
            </div>
            <div className={`target ${this.checkEqual() ? "gold" : ""}`}>{this.target}</div>
          </div>
        </div>
        <div className="controls">
          <div className="number-control">{this.numbersControl()}</div>
          <div className="operator-control">
            {this.operatorControl()}
            <button onClick={() => this.undo()}className="operator">←</button>
          </div>
        </div>
        {this.confettiContainer()}

        <div className={`modal-container ${this.state.showModal ? "show" : ""}`}>
          <div className="modal">
            <div className="close" onClick={() => this.closeModal()}>×</div>
            {this.modalHeading()}
            <div className="stats">
              <div>
                <div className="stat">{this.stats.played}</div>
                <div className="title">Total Played</div>
              </div>
              <div>
                <div className="stat">{this.stats.streak}</div>
                <div className="title">Current Streak</div>
              </div>
              <div>
                <div className="stat">{this.stats.perfect}</div>
                <div className="title">Perfect</div>
              </div>
            </div>
            {this.modalActions()}
            <div className="next-timer">
              <div>Next Daisy Chain</div>
              {this.getTimer()}
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default Container;
